/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { animated, useSpring } from 'react-spring'
import Layout from '../../components/layout'
// import GramercyIcon from '../assets/gramercyIcon'
import GramercyType from '../../assets/gramercyType'

const AnimatedBox = animated(Box)

const ContactThankYou = () => {
  const context = useThemeUI()
  const { theme } = context

  const themeIconColorFrom = theme.colors.iconFrom
  const themeIconColor = theme.colors.iconSecondary

  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })
  const buttonFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 650,
  })
  return (
    <Layout>
      <Flex
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AnimatedBox
          style={headingFade}
          sx={{
            textAlign: 'center',
            mt: [4, 5, 6],
          }}
        >
          <Styled.h2 sx={{ variant: 'text.heading' }}>Thank you for your enquiry!</Styled.h2>
        </AnimatedBox>
        <AnimatedBox
          style={subtitleFade}
          sx={{
            px: [4, 5, 6],
            textAlign: 'center',
          }}
        >
          <Styled.h4 sx={{ variant: 'text.subheading' }}>We'll be in touch with you shortly.</Styled.h4>
        </AnimatedBox>

        <AnimatedBox style={buttonFade} sx={{ textAlign: 'center' }}>
          {/* <Link to={'/'}>
            <ButtonAnimated m={3} variant="primary" scaleActive="1.1" label="Head on home" />
          </Link> */}
          {/* <ButtonAnimated m={3} variant="outline" scaleActive="1.1" label="Explore some more" /> */}
        </AnimatedBox>

        <Flex sx={{ width: '100%', contain: 'content', justifyContent: 'center', alignItems: 'center', py: '5', px: '2', flexDirection: 'row' }}>
          {/* <div sx={{ px: [0, 5], width: '200px' }}>
            <GramercyIcon width="100%" height="100%" fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
          </div> */}
          <div sx={{ px: [1, 3, 4, 4], width: ['60%', '50%', '50%', '50%'] }}>
            <GramercyType width="100%" height="100%" fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
          </div>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default ContactThankYou
